<template>
  <section class="not-found">
    <div class="not-found__title">404</div>
    <div class="not-found__subtitle">Страница не найдена</div>
    <div class="not-found__link">
      <base-link
        :to="{ name: 'offer' }"
        :button="true"
        :size="16"
        :family="'Roboto-bold'"
      >
        На главную
      </base-link>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__title {
    font-family: $bold;
    font-size: 170px;
    line-height: 175px;
    font-weight: 700;
    color: $primary;
    margin-top: -29px;
  }
  &__subtitle {
    font-family: $bold;
    font-size: 34px;
    line-height: 40px;
    font-weight: 700;
    color: $black;
    margin-bottom: 43px;
  }
  &__link {
    width: 120px;
    height: 40px;
  }
}
@media (max-width: 992px) {
  .not-found {
    padding-top: 38px;
    &__title {
      font-size: 113px;
      line-height: 124px;
      margin: 11px 7px 0 0;
    }
    &__subtitle {
      font-size: 27px;
      line-height: 30px;
      margin-bottom: 32px;
    }
  }
}
</style>
